import React from "react";
import { Link } from "gatsby";
import Container from "./container";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import ShowcaseItem from "./showcase-item";

import * as styles from "../styles/components/showcase-section.module.css";

const ShowcaseSection = (props) => {

  const {
    title,
    showcase,
    button
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        {title && (<h2 className={styles.title}>{title}</h2>)}
        <div className="grid">
          {showcase && showcase.length > 0 && (
            <>
              {showcase.map(node => {
                return (
                  <div key={node._key} className={cn("col-12 col-md-6 col-xl-4", styles.item)}>
                    <ShowcaseItem showcase={node.showcase} />
                  </div>
                );
              })}
            </>
          )}
        </div>
        {button && (button.url || (button.document && button.document.asset)) && (
          <div>
            {button.document && button.document.asset ? (
              <a href={button.document.asset.url + "/" + button.document.asset.originalFilename} className={cn("btn", button.secondaryButton && "secondary")} target="_blank" rel="noreferrer">
                {button.text}
                <Icon symbol="arrow-right" />
              </a>
            ) : (
              <>
                {button.blank ? (
                  <a href={button.url} className={cn("btn", button.secondaryButton && "secondary")} target="_blank" rel="noreferrer">
                    {button.text}
                    <Icon symbol="arrow-right" />
                  </a>
                ) : (
                  <Link to={button.url} className={cn("btn", button.secondaryButton && "secondary")}>
                    {button.text}
                    <Icon symbol="arrow-right" />
                  </Link>
                )}
              </>
            )}
          </div>
        )}
      </Container>
    </div>
  );
}

export default ShowcaseSection;

import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import { toPlainText } from "../lib/helpers";

import PageHeader from "../components/partner-page-header";
import PartnerMain from "../components/partner-main";
import ShowcaseSection from "../components/showcase-section";
import LatestPosts from "../components/posts-latest";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query LandingPageTemplateQuery($id: String!) {
    landing: sanityPageLanding(id: { eq: $id }) {
      id
      title
      logo {
        asset {
          _id
        }
        alt
      }
      customPageTitle
      _rawDescription
      button {
        text
        url
        secondaryButton
        blank
        document {
          asset {
            url
            originalFilename
          }
        }
      }
      thumbnail {
        ...SanityImage
        alt
      }
      flexibleContent {
        ... on SanityFc2ColumnImage {
          _key
          _type
          imageLeft {
            ...SanityImage
            alt
            caption
          }
          imageRight {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcContentBlock {
          _key
          _type
          _rawContent
        }
        ... on SanityFcImage {
          _key
          _type
          fullwidth
          image {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcVideo {
          _key
          _type
          caption
          url
        }
      }
      marketoFormId
      showcaseSection {
        title
        showcase {
          _key
          showcase {
            title
            slug {
              current
            }
            thumbnail {
              ...SanityImage
              alt
            }
            categories {
              _key
              showcaseCategory {
                title
                slug {
                  current
                }
              }
            }
          }
        }
      }
      latestPosts {
        ... on SanityLibraryReference {
          _key
          _type
          library {
            title
            slug {
              current
            }
            thumbnail {
              ...SanityImage
              alt
            }
            types {
              _key
              libraryType {
                title
                slug {
                  current
                }
              }
            }
          }
        }
        ... on SanityNewsReference {
          _key
          _type
          news {
            title
            slug {
              current
            }
            publicationDate
            thumbnail {
              ...SanityImage
              alt
            }
          }
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }

    news: allSanityNews(
      sort: { fields: [publicationDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          publicationDate
          thumbnail {
            ...SanityImage
            alt
          }
        }
      }
    }
  }
`;

const LandingPageTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const landing = (data || {}).landing;
  const news = (data || {}).news.edges;

  const title = landing.seo?.title || landing.title;
  const description = landing.seo?.description || toPlainText(landing._rawDescription);
  const image = landing.seo?.thumbnail || landing.thumbnail;
  const autoSiteTitle = !landing.seo?.title;
  const marketoFormId = landing.marketoFormId;


  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      {(landing.title || landing.customPageTitle) && (<PageHeader marketoFormId={marketoFormId} title={landing.customPageTitle} name={landing.title} description={landing._rawDescription} button={landing.button} logo={landing.logo} image={landing.thumbnail} />)}
      {landing.flexibleContent && (<PartnerMain content={landing.flexibleContent} marketoFormId={marketoFormId} />)}
      {landing.showcaseSection && (<ShowcaseSection {...landing.showcaseSection} />)}
      {(news || landing.latestPosts) && (<LatestPosts dPosts={news} mPosts={landing.latestPosts} />)}
    </Layout>
  );
};

export default LandingPageTemplate;

import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "../styles/components/marketo-form.css";
import { useUrlParameters } from "../context/urlParametersContext";
import platform from "platform";
import { useEffect } from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import { navigate } from "gatsby";
const MarketoEmbed = ({id}) => {
  const recaptchaRef = React.createRef();
  const RECAPTCHA_KEY = process.env.GATSBY_CAPTCHA_KEY;
  const urlParameters = useUrlParameters();
  const timezoneInfo = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [captchaDone, setCaptchaDone] = useState(false);
  useEffect(() => {
    // Load the Marketo form
    let mktforms2 = window.MktoForms2;
    if (mktforms2) {
      
      mktforms2.loadForm("https://lp.pureweb.com", "913-VOO-268", id);

      mktforms2.whenReady(function (form) {
        // Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
          // Get the form's jQuery element and hide it
          form.getFormElem().hide();
          navigate("/thank-you-contact/");
          
          return false;
        });
      });
    }
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!captchaDone) {
        await recaptchaRef.current.executeAsync();
        setCaptchaDone(true);
      }
      // Get the form
      let form = window.MktoForms2.getForm(id);
      if (!form) {
        throw new Error("Form not found.");
      }

      // Parse platform info
      const info = platform.parse();

      // Fetch IP information
      const ipInfoCall = await fetch("https://ipinfo.io/json", {
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_IPINFO_TOKEN}`,
        },
      });
      if (!ipInfoCall.ok) {
        throw new Error("Failed to fetch IP information.");
      }
      const ipInfo = await ipInfoCall.json();

      // Add hidden fields to the form
      const deviceInfo = window.navigator.userAgent;
      const osInfo = info.os.family;
      const browserInfo = info.name;
      const formValues = form.getValues();
      form.addHiddenFields({
        Unsubscribed:
          formValues.Agreed_to_be_Contacted__c === "yes" ? false : true,
        LeadSource: "Website - Form - Get In Touch",
        Device__c: deviceInfo,
        Operating_System__c: osInfo,
        Browser__c: browserInfo,
        Time_Zone__c: timezoneInfo,
        Google_Analytics_Client_ID__c: urlParameters?.gad_source,
        GCLID__c: urlParameters?.gclid,
        Last_Touch_Campaign__c: urlParameters?.utm_campaign,
        Last_Touch_Medium__c: urlParameters?.utm_medium,
        Last_Touch_Content__c: urlParameters?.utm_content,
        Last_Touch_Source__c: urlParameters?.utm_source,
        Last_Touch_Term__c: urlParameters?.utm_term,
        City__c: ipInfo?.city,
        Region__c: ipInfo?.region,
        Country__c: ipInfo?.country,
        OriginalReferrer: document.referrer,
      });

      // Get updated form values and validate
      let validation = form.validate();

      // Handle form submission
      if (validation) {
        let submission = form.submit();
        console.log(submission)
      } else {
        console.log("Form validation failed.");
      }
    } catch (error) {
      console.error("Error occurred during form submission:", error);
    }
  };
  return (
    <div className="marketoEmbed">
      <form id={`mktoForm_${id}`}>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={RECAPTCHA_KEY}
        />
      </form>
      <button
        className={cn("btn Marketobutton")}
        type="submit"
        id="submit-form"
        onClick={handleSubmit}
      >
        Submit
        <Icon symbol="arrow-right" />
      </button>
    </div>
  );
};

export default MarketoEmbed;

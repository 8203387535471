import React from "react";
import { Link } from "gatsby";
import Icon from "./icon";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { cn, buildImageObj } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/post-page-header.module.css";

const PageHeader = (props) => {

  const {
    title,
    name,
    description,
    logo,
    button,
    image,
    marketoFormId,
  } = props;

  return (
    <div className={styles.root}>
      <div className={cn("grid", styles.grid)}>
        <div className={cn("col-12 ", (image && image.asset) ? "col-md-6" : styles.noImg, styles.col, styles.text)}>
          <Container landingPageContainer={marketoFormId ? true : false}>
            <div className={styles.contentWrap}>
              {logo && logo.asset && (
                <div className={styles.logo}>
                  <img
                    src={imageUrlFor(buildImageObj(logo))
                      .auto("format")
                      .url()}
                    alt={logo.alt}
                  />
                </div>
              )}
              {(title || name) && (<h1 className={cn("h4", styles.title)}>{title ? title : name}</h1>)}
              {description && (<PortableText blocks={description} />)}
              {button && (button.url || (button.document && button.document.asset)) && (
                <>
                  {button.document && button.document.asset ? (
                    <a href={button.document.asset.url + "/" + button.document.asset.originalFilename} className={cn("btn", button.secondaryButton && "secondary", styles.button)} target="_blank" rel="noreferrer">
                      {button.text}
                      <Icon symbol="arrow-right" />
                    </a>
                  ) : (
                    <>
                      {button.blank ? (
                        <a href={button.url} className={cn("btn", button.secondaryButton && "secondary", styles.button)} target="_blank" rel="noreferrer">
                          {button.text}
                          <Icon symbol="arrow-right" />
                        </a>
                      ) : (
                        <Link to={button.url} className={cn("btn", button.secondaryButton && "secondary", styles.button)}>
                          {button.text}
                          <Icon symbol="arrow-right" />
                        </Link>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Container>
        </div>
        {image && image.asset && (
          <div className={cn("col-12 col-md-6", styles.col, styles.image)}>
            <img
              src={imageUrlFor(buildImageObj(image))
                .auto("format")
                .url()}
              alt={image.alt}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PageHeader;

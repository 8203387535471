import React from "react";
import Container from "./container";
import FlexibleContent from "../components/flexible-content";

import * as styles from "../styles/components/partner-main.module.css";
import MarketoEmbed from "./marketo-embed";

const PartnerMain = (props) => {
  const { content, marketoFormId } = props;

  return (
    <div className={styles.root}>
      <Container landingPageContainer={marketoFormId ? true : false}>
        <div className={marketoFormId ? styles.contentFlex : styles.content}>
          {marketoFormId && content ? (
            <div className={styles.flexCol}>
              <FlexibleContent items={content} />
            </div>
          ) : (
            content && <FlexibleContent items={content} />
          )}
          {marketoFormId && <MarketoEmbed id={marketoFormId} />}
        </div>
      </Container>
    </div>
  );
};

export default PartnerMain;
